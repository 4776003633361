import React from 'react'
import { navigate } from 'gatsby'
import Form from './partials/NetlifyForm'
import Input from './partials/Input'
import File from './partials/File'
import Submit from './partials/Submit'
import TextArea from './partials/TextArea'

const ImageCaptureBookingForm = () => {
  const handleSubmit = async (event) => {
    const formData = new FormData(event.target)

    const formObj = {}
    for (const [key, value] of formData.entries()) {
      formObj[key] = value
    }

    await submitForm(formObj)
  }

  const submitForm = async (formData) => {
    const response = await fetch('/api/image-capture/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: formData.email,
        form_data: formData,
      }),
    })

    if (response.ok) {
      console.log('Image Capture response email sent successfully')
      navigate('/image-capture/success')
    } else {
      console.error('Error sending Image Capture response email')
    }
  }

  return (
    <Form
      className="c-contactUsForm"
      formName="Image Capture Booking Form"
      successPage="/image-capture/success"
      fileUpload
      onSubmit={handleSubmit}
    >
      <p className="mb-3 pb-3">
        Please complete the form below to book your products in with us for
        Image Capture.
      </p>

      <div className="form-group row">
        <div className="col-12">
          <h2>Your Details</h2>
          <p>Please provide your contact details below:</p>
        </div>
      </div>

      <Input name="name" label="Your name" required={true} />

      <Input name="email" label="Your email" type="email" required={true} />

      <Input name="company" label="Company name" required={true} />

      <Input name="telephone" label="Phone number" type="tel" required={true} />

      <div className="form-group row pt-4">
        <div className="col-12">
          <h2>Image Capture Service</h2>
          <p>
            Please select which service you require (Packshot = individual
            product, Case Shot = case + individual product) below:
          </p>
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="required_service"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
        >
          Required Service
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="required_service_packshot"
              name="required_service_packshot"
              value="Yes"
            />
            <label
              className="form-check-label"
              htmlFor="required_service_packshot"
            >
              Packshot
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="required_service_caseshot"
              name="required_service_caseshot"
              value="Yes"
            />
            <label
              className="form-check-label"
              htmlFor="required_service_caseshot"
            >
              Case Shot
            </label>
          </div>
        </div>
      </div>

      <Input
        name="products_total"
        label="Total number of Products being sent"
        required={true}
      />

      <div className="form-group row pt-4">
        <div className="col-12">
          <h2>Storage &amp; Delivery:</h2>
          <p>
            Please select the product's storage category and expected delivery
            date below:
          </p>
        </div>
      </div>

      <div className="form-group row">
        <label
          htmlFor="product_storage"
          className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
        >
          Product Storage Information
        </label>
        <div className="col-12 col-sm-8 col-md-9 col-lg-8">
          <div class="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="product_storage_ambient"
              name="product_storage_ambient"
              value="Yes"
            />
            <label
              className="form-check-label"
              htmlFor="product_storage_ambient"
            >
              Ambient
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="product_storage_chilled"
              name="product_storage_chilled"
              value="Yes"
            />
            <label
              className="form-check-label"
              htmlFor="product_storage_chilled"
            >
              Chilled
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="product_storage_fresh"
              name="product_storage_fresh"
              value="Yes"
            />
            <label className="form-check-label" htmlFor="product_storage_fresh">
              Fresh
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="product_storage_frozen"
              name="product_storage_frozen"
              value="Yes"
            />
            <label
              className="form-check-label"
              htmlFor="product_storage_frozen"
            >
              Frozen
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="product_storage_non_food"
              name="product_storage_non_food"
              value="Yes"
            />
            <label
              className="form-check-label"
              htmlFor="product_storage_non_food"
            >
              Non Food
            </label>
          </div>
        </div>
      </div>

      <Input
        name="expected_delivery_total"
        label="Expected Delivery Date"
        type="date"
        required={true}
      />

      <div className="form-group row pt-4">
        <div className="col-12">
          <h2>Additional Information</h2>
          <p>
            If you wish, you can upload a list of the products you're sending to
            us and/or add any additional information using the 'Additional
            Comments' box below:
          </p>
        </div>
      </div>

      <TextArea name="additional_comments" label="Additional comments" />

      <File name="attachment_file" label="Upload Attachment" />

      <Submit />
    </Form>
  )
}

export default ImageCaptureBookingForm
