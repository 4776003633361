import React from 'react'
import PropTypes from 'prop-types'

const Input = ({ name, label, type, required }) => {
  return (
    <div className="form-group row">
      <label
        htmlFor={name}
        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
      >
        {`${label}${required ? '*' : ''}`}
      </label>
      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
        <input
          type={type}
          className="form-control"
          id={name}
          name={name}
          required={required}
        />
      </div>
    </div>
  )
}

Input.defaultProps = {
  type: 'text',
  required: false,
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'date']),
  required: PropTypes.bool,
}

export default Input
