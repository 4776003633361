import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import ImageCaptureBookingForm from '../../components/forms/ImageCaptureBooking'

const ImageCaptureBookingsPage = () => {
  const { parentPage, currentPage } = useStaticQuery(graphql`
    query ImageCaptureBookingsPageQuery {
      parentPage: markdownRemark(
        frontmatter: { path: { eq: "/image-capture" } }
      ) {
        frontmatter {
          title
          path
        }
      }
      currentPage: markdownRemark(
        frontmatter: { path: { eq: "/image-capture/bookings" } }
      ) {
        frontmatter {
          title
          path
        }
      }
    }
  `)

  const parentPageData = parentPage.frontmatter
  const pageData = currentPage.frontmatter

  const breadcrumbs = [
    {
      name: parentPageData.title,
      uri: parentPageData.path,
    },
    {
      name: pageData.title,
      uri: pageData.path,
    },
  ]

  return (
    <Page className="p-solutions p-solutions--manufacturers p-sign-up has-fingerPrints">
      <SEO title={pageData.title} />
      <Schema breadcrumbs={breadcrumbs} />

      <section className="c-section c-section--pageHeader">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-pageHeader">
              <div className="row">
                <div className="col-12 col-sm-7 col-md-9">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      <span className="c-pageHeader__title">
                        <strong>Image Capture</strong>
                      </span>
                    </div>
                    <div className="col-12 col-lg-8">
                      <h1 className="c-pageHeader__intro">
                        <strong>Booking Form</strong>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section c-section--contact-us">
        <div className="c-section__outer container">
          <div className="c-section__inner pt-0 pb-1">
            <div className="c-sectionTitle">
              <div className="row">
                <div className="col-12 col-lg-8 offset-lg-3">
                  <ImageCaptureBookingForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

export default ImageCaptureBookingsPage
