import React from 'react'
import PropTypes from 'prop-types'

const File = ({ name, label, required }) => (
  <div className="form-group row">
    <label
      htmlFor={name}
      className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
    >
      {`${label}${required ? '*' : ''}`}
    </label>
    <div className="col-12 col-sm-8 col-md-9 col-lg-8">
      <input
        type="file"
        className="form-control-file"
        id={name}
        name={name}
        accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.csv,.xls,.xlsx"
        required={required}
      />
    </div>
  </div>
)

File.defaultProps = {
  required: false,
}

File.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default File
