import React from 'react'
import PropTypes from 'prop-types'

const TextArea = ({ name, label, rows, required }) => {
  return (
    // <div className="form-group row mb-4"
    <div className="form-group row">
      <label
        htmlFor={name}
        className="col-12 col-sm-4 col-md-3 col-lg-4 col-form-label"
      >
        {`${label}${required ? '*' : ''}`}
      </label>
      <div className="col-12 col-sm-8 col-md-9 col-lg-8">
        <textarea
          className="form-control"
          id={name}
          name={name}
          required={required}
          rows={rows}
        ></textarea>
      </div>
    </div>
  )
}

TextArea.defaultProps = {
  rows: 5,
  required: false,
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  required: PropTypes.bool,
}

export default TextArea
